import React from "react";
import { Select } from "antd";
import countries from '../json/countries.json';

const CountriesSelectInput = ({ value, onChange }) => {
	
	return (
		<>
			<Select
				showSearch
				placeholder="Select country"
				options={countries}
				style={{ width: "100%" }}
				value={value || undefined}
				title="Country"
				onChange={(value) => onChange('country', value)} />
		</>
	);
};

export default CountriesSelectInput;
