import React from 'react';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Auth from "../pages/Auth";
import CreatePost from "../pages/CreatePost";
import Posts from "../pages/Posts";
import About from "../pages/About";
import Home from "../pages/Home";
import EditPost from "../pages/EditPost";

const AppRoutes = () => {
	return (
		<Router>
			<div className="flex flex-col min-h-screen">
				<main className="flex-grow">
					<Routes>
						<Route path="/" element={ <Auth /> }/>
						<Route path="/dashboard/posts" element={ <Posts /> } />
						<Route path="/dashboard/posts/create" element={ <CreatePost /> } />
						<Route path="/dashboard/posts/edit/:id" element={ <EditPost /> } />
						<Route path="/dashboard/content-management/about" element={ <About /> } />
						<Route path="/dashboard/content-management/home" element={ <Home /> } />
					</Routes>
				</main>
			</div>
		</Router>
	)
};

export default AppRoutes;
