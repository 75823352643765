import Dashboard from "../components/Dashboard";
import {Button, message, Upload, Image, List} from "antd";
import {UploadOutlined, DeleteOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import axios from "axios";
import '../assets/css/home.css';

const Home = () => {
	
	const [homeImages, setHomeImages] = useState([]);
	
	const beforeUpload = (file) => {
		const acceptedMimeTypes = ["image/png", "image/jpeg", "image/gif", "image/jpg", "image/webp"];
		
		if (!acceptedMimeTypes.includes(file.type)) {
			message.error('You can only upload JPG/PNG file!');
			return Upload.LIST_IGNORE;
		}
	};
	
	const updateDocument = async (path) => {
		try {
			await axios.post(
				`${process.env.REACT_APP_NODE_APP_API_URL}/content-management/home`,
				JSON.stringify({imagePath: path}),
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					withCredentials: true
				}
			);
			
			
		} catch (ex) {
			console.log(ex)
		}
	};
	
	const homeImageProps = {
		name: 'file',
		action: `${process.env.REACT_APP_NODE_APP_API_URL}/posts/assets/upload`,
		withCredentials: true,
		beforeUpload,
		data: {uniqueId: 'home'},
		onRemove: () => false,
		async onChange(info)  {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				await updateDocument(info.file.response.filePath);
				await setDocumentImage();
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		maxCount: 10,
	}
	
	const setDocumentImage = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_NODE_APP_API_URL}/content-management/home`,
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				}
			);
			
			if (response.status === 200) {
				if (!response.data.images) {
					return;
				}
				
				setHomeImages(response.data.images.map((image, index) => ({
					uid: image._id,
					name: `Image ${index + 1}`,
					url: image.imagePath,
					status: 'done'
				})))
			}
			
			
		} catch (ex) {
			console.log(ex)
		}
	};
	
	const handleDelete = async (uid) => {
		const response = await axios.delete(
			`${process.env.REACT_APP_NODE_APP_API_URL}/content-management/home/${uid}`,
			{
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				withCredentials: true,
			}
		)
		
		if (response.status === 200) {
			message.success('File deleted successfully');
			setDocumentImage();
		}
	};
	
	useEffect(() => {
		setDocumentImage()
	}, []);
	
	return (
		<>
			<Dashboard>
				<div>
					<h1 className="font-poppins" style={{fontSize: 28, fontWeight: 500}}>Home Page</h1>
				</div>
				<div className="mb-10">
					
					<List
						grid={{ gutter: 16, column: 4 }}
						dataSource={homeImages}
						renderItem={item => (
							<List.Item>
								<div className="image-item">
									<Image src={item.url} style={{
										width: '450px',
										height: '350px',
										objectFit: 'contain',
										padding: '5px',
										boxShadow: '#00000017 7px 5px 9px 1px',
										borderRadius: 10
									}}/>
									<Button
										icon={<DeleteOutlined/>}
										onClick={() => handleDelete(item.uid)}
										className="delete-button"
										danger
									/>
									
								</div>
							</List.Item>
						)}
					/>
				
				</div>
				
				<hr/>
				
				<div className="mt-10">
					<Upload listType="picture" {...homeImageProps}>
							<Button icon={<UploadOutlined/>} type="primary">Upload Main Image</Button>
					</Upload>
				</div>
			</Dashboard>
		</>
	);
};

export default Home;
