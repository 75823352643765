import React, {useEffect} from 'react';
import { Layout, Menu } from 'antd';
import { LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import { Link, useLocation} from 'react-router-dom';
import '../assets/css/dashboard.css';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


const Dashboard = ({ children }) => {
	const location = useLocation();
	
	const [selectedKeys, setSelectedKeys] = React.useState([]);
	const [openKeys, setOpenKeys] = React.useState([]);
	const handleOpenChange = (keys) => {
		setOpenKeys(keys);
	}
	
	useEffect(() => {
		const path = location.pathname;
		const keys = path.split('/').filter(Boolean);
		
		// Determine selected key and open submenu based on the path
		if (keys[1] === 'posts') {
			setSelectedKeys([keys[2] === 'create' ? '2' : '1']);
			setOpenKeys(['sub1']);
		} else if (keys[1] === 'content-management') {
			setSelectedKeys([keys[2] === 'home' ? '4' : '5']);
			setOpenKeys(['sub2']);
		}
	}, [location.pathname]);
	
	
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Header className="header">
				<div className="logo" />
				<Menu className="header-items-section" theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
					<Menu.Item key="1">Dashboard</Menu.Item>
					<Menu.Item key="2">
						<a href="http://archbranding.se/"
						   target="_blank" rel="noreferrer">
							Arch Branding
						</a>
						
					</Menu.Item>
				</Menu>
			</Header>
			<Layout>
				<Sider width={250} className="site-layout-background">
					<Menu
						mode="inline"
						selectedKeys={selectedKeys}
						openKeys={openKeys}
						onOpenChange={handleOpenChange}
						style={{ height: '100%', borderRight: 0 }}
					>
						<SubMenu key="sub1" icon={<LaptopOutlined />} title="Posts">
							<Menu.Item key="1"><Link to="/dashboard/posts">Post List</Link></Menu.Item>
							<Menu.Item key="2"><Link to="/dashboard/posts/create">Add Post</Link></Menu.Item>
						</SubMenu>
						<SubMenu key="sub2" icon={<NotificationOutlined />} title="Content Management">
							<Menu.Item key="4"><Link to="/dashboard/content-management/home">Home</Link></Menu.Item>
							<Menu.Item key="5"><Link to="/dashboard/content-management/about">About</Link></Menu.Item>
						</SubMenu>
					</Menu>
				</Sider>
				<Layout style={{ padding: '0 24px 24px' }}>
					<Content
						className="site-layout-background"
						style={{
							padding: 24,
							margin: 0,
							minHeight: 280,
						}}
					>
						{children}
					</Content>
					<Footer style={{ textAlign: 'center' }}>Arch Branding ©2024</Footer>
				</Layout>
			</Layout>
		</Layout>
	
	);
}

export default Dashboard;
