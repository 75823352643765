import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const App = () => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(null);
	const navigate = useNavigate();
	
	const onFinish = async (values) => {
		setLoading(true);
		setError(null);
		
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_NODE_APP_API_URL}/auth`,
				values,
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					withCredentials: true
				}
			);
			
			if (response.status === 200) {
				navigate('/dashboard/posts')
			}
			
		} catch (error) {
			setError('Incorrect username or password');
		} finally {
			setLoading(false)
		}
		console.log('Received values of form: ', values);
	};
	return (
		<div>
			<div className="flex justify-center items-center" style={{height: '100vh'}}>
				<Form
					name="normal_login"
					className="login-form"
					initialValues={{
						remember: true,
					}}
					onFinish={onFinish}
				>
					<Form.Item
						name="username"
						rules={[
							{
								required: true,
								message: 'Please input your Username!',
							},
						]}
					>
						<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
					</Form.Item>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: 'Please input your Password!',
							},
						]}
					>
						<Input
							prefix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder="Password"
						/>
					</Form.Item>
					
					{error && <div style={{ color: 'red'}}>{error}</div>}
					
					<Form.Item className="flex justify-center">
						<Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
							Log in
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};
export default App;
