import React, {useEffect, useState} from "react";
import Dashboard from "../components/Dashboard";
import axios from "axios";
import {Button, List, message, Pagination} from "antd";
import { useNavigate } from "react-router-dom";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

const Posts = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [posts, setPosts] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const navigate = useNavigate();
	const pageSize = 20;
	
	const fetchPosts = async (page) => {
		try	{
			const response = await axios.get(`${process.env.REACT_APP_NODE_APP_API_URL}/posts?page=${page}`, {withCredentials: true});
			setPosts(response.data.posts);
			setCurrentPage(response.data.currentPage);
			setTotalPages(response.data.totalPages);
		} catch (error) {
			console.error('Error fetching posts:', error);
		}
	};
	
	useEffect(() => {
		fetchPosts(currentPage);
	}, [currentPage])
	
	const handlePageChange = (page) => {
		setCurrentPage(page);
	}
	
	const handleDelete = async (id) => {
		try {
			await axios.delete(`${process.env.REACT_APP_NODE_APP_API_URL}/posts/${id}`, { withCredentials: true });
			message.success('Post deleted successfully.');
			fetchPosts(currentPage)
		} catch (error) {
			console.error('Error deleting post:', error);
			message.error('Failed to delete post');
		}
	}
	
	const handleUpdate = (id) => {
		navigate('/dashboard/posts/edit/' + id);
	}
	
	return (
		<>
			<Dashboard>
				<div>
					<div>
						<h1 className="font-poppins" style={{fontSize: 28, fontWeight: 500}}>Posts</h1>
					</div>
					
					<div>
						<List
							itemLayout="horizontal"
							dataSource={posts}
							renderItem={post => (
								<List.Item key={post.id}
										   actions={[
											   <Button type="primary" icon={<DeleteOutlined />} danger onClick={() => handleDelete(post._id)}>Delete</Button>,
											   <Button type="primary"  icon={<EditOutlined />} onClick={() => handleUpdate(post._id)}>Update</Button>
										   ]}
								>
									<List.Item.Meta
										title={<span className="font-poppins" style={{fontSize: 19}}>{post.title}</span>}
										description={post.description}
										avatar={<img src={post?.thumbNail?.filePath } alt="Description" style={{ width: '250px', height: '250px', objectFit: 'cover' }} />}
									></List.Item.Meta>
								</List.Item>
							)}
						/>
						<Pagination
							current={currentPage}
							pageSize={pageSize}
							total={totalPages * pageSize}
							onChange={handlePageChange}
							style={{ marginTop: '16px', textAlign: "center" }}
							/>
					</div>
				</div>
			</Dashboard>
		</>
	);
};

export default Posts;
