import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Input, Select, Button, Upload, message, Typography} from 'antd';
import Dashboard from '../components/Dashboard';
import CountriesSelectInput from '../components/CountriesSelectInput';
const { TextArea } = Input;

const EditPost = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [fields, setFields] = useState({
		title: '',
		postType: ['all'],
		client: '',
		category: '',
		country: '',
		service: '',
		description: '',
	});
	const [mainImage, setMainImage] = useState({ uploaded: false, filePath: '' });
	const [thumbNail, setThumbnail] = React.useState({uploaded: false, filePath: ''});
	const [inputsForMedia, setMediaInputs] = useState([]);
	const [uniqueId, setUniqueId] = useState('');
	const [uniqueIdTh, setUniqueIdTh] = useState('');
	
	useEffect(() => {
		const getPost = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_NODE_APP_API_URL}/posts/${id}`, {
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					withCredentials: true,
				});
				
				const post = response.data.post;
				setFields({
					title: post.title,
					postType: post.postType,
					client: post.client,
					category: post.category,
					country: post.country,
					service: post.service,
					description: post.description,
				});
				setMainImage(post.mainImage);
				setThumbnail(post.thumbNail);
				setMediaInputs(post.inputsForMedia);
				setUniqueId(post.assetsDir);
			} catch (error) {
				console.error('Error fetching post:', error);
			}
		};
		
		getPost();
	}, [id]);
	
	const handleFieldChanges = (name, value) => {
		setFields((prevFields) => ({
			...prevFields,
			[name]: value,
		}));
	};
	
	const beforeUpload = (file) => {
		const acceptedMimeTypes = ["image/png", "image/jpeg", "image/gif", "image/jpg", "image/webp"];
		
		if (!acceptedMimeTypes.includes(file.type)) {
			message.error('You can only upload JPG/PNG file!');
			return Upload.LIST_IGNORE;
		}
	};
	
	const additionalMediaProps = {
		name: 'file',
		action: `${process.env.REACT_APP_NODE_APP_API_URL}/posts/assets/upload`,
		withCredentials: true,
		data: {uniqueId: uniqueId},
		maxCount: 1,
		beforeUpload
	};
	
	const handleDeleteInput = (index) => {
		const newFields = inputsForMedia.filter((item, i) => i !== index);
		setMediaInputs(newFields);
	};
	
	const handleDeleteMainImage = () => {
		setMainImage({uploaded: false, filePath: ''});
	};
	
	const handleDeleteThumbNail = () => {
		setThumbnail({uploaded: false, filePath: ''});
	};
	
	const handleMediaUpload = (info, index) => {
		const newFields = [...inputsForMedia];
		if (info.file.status !== 'uploading') {
			console.log(info.file, info.fileList);
		}
		if (info.file.status === 'done') {
			newFields[index].uploaded = true;
			newFields[index].value = info.file.response.filePath;
			newFields[index].filePath = info.file.response.filePath;
			
			message.success(`${info.file.name} file uploaded successfully`);
		} else if (info.file.status === 'error') {
			inputsForMedia[index].value = null;
			inputsForMedia[index].uploaded = false;
			newFields[index].filePath = '';
			message.error(`${info.file.name} file upload failed.`);
		}
		setMediaInputs(newFields)
		console.log(inputsForMedia)
	}
	
	const handleIframeAttachment = (info, index) => {
		const newFields = [...inputsForMedia];
		inputsForMedia[index].value = info.target.value;
		inputsForMedia[index].isValid = validateUrl(info.target.value);
		setMediaInputs(newFields);
	}

	
	const handleSubmit = async () => {
		if (!fields.title) {
			message.error('Please enter a title!');
			return;
		} else if (!mainImage?.uploaded) {
			message.error('Please add a main image!');
			return;
		} else if (!thumbNail?.uploaded) {
			message.error('Please add a thumbnail!');
			return;
		}
		
		if (inputsForMedia.some((input) => input.type === 'link' && !validateUrl(input.value))) {
			message.error('Please provide valid YouTube/Vimeo links for link inputs.');
			return;
		}
		
		const formData = {
			...fields,
			mainImage,
			thumbNail,
			inputsForMedia,
			assetsDir: uniqueId,
		};
		
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_NODE_APP_API_URL}/posts/${id}`,
				JSON.stringify(formData),
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					withCredentials: true,
				}
			);
			
			if (response.status === 200) {
				message.success('Post updated successfully');
				setTimeout(() => {
					navigate('/dashboard/posts');
				}, 1500);
			}
		} catch (error) {
			message.error('Error updating post: ' + error.message);
		}
	};
	
	const validateUrl = (url) => {
		const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
		const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/;
		return youtubeRegex.test(url) || vimeoRegex.test(url);
	};
	
	const sharedImageProps = {
		name: 'file',
		action: `${process.env.REACT_APP_NODE_APP_API_URL}/posts/assets/upload`,
		withCredentials: true,
		beforeUpload,
		maxCount: 1,
	}
	
	const uploadMainImageProps = {
		...sharedImageProps,
		data: {uniqueId: uniqueId},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				setMainImage({
					uploaded: true,
					filePath: info.file.response.filePath
				})
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onRemove(file) {
			setMainImage({
				uploaded: false,
				filePath: '',
			})
			message.success(`${file.name} file removed successfully`);
		},
	};
	
	
	const thumbNailProps = {
		...sharedImageProps,
		data: {uniqueId: uniqueIdTh},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				setThumbnail({
					uploaded: true,
					filePath: info.file.response.filePath
				})
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onRemove(file) {
			setThumbnail({
				uploaded: false,
				filePath: '',
			})
			message.success(`${file.name} file removed successfully`);
		},
	}
	
	const addAssetInput = () => {
		if (inputsForMedia.some(input => input.type === 'file' && input.value === '')) {
			message.error('Attach images for all uploaders before adding a new one');
			return;
		}
		
		setMediaInputs([...inputsForMedia, {id: inputsForMedia.length + 1, value: '', type: 'file'}])
	};
	
	const addLinkInput = () => {
		setMediaInputs([...inputsForMedia, {id: inputsForMedia.length + 1, value: '', type: 'link', isValid: false}])
	};
	
	return (
		<Dashboard>
			<div>
				<div>
					<h1 className="font-poppins" style={{ fontSize: 28, fontWeight: 500 }}>Edit Post</h1>
				</div>
				<div>
					
					<Typography.Text>Title</Typography.Text>
					<Input
						name="title"
						placeholder="Title"
						value={fields.title}
						onChange={(e) => handleFieldChanges(e.target.name, e.target.value)}
					/>
					<br/><br/>
					
					<Typography.Text>Thumbnail</Typography.Text>
					
					<div className="flex items-center gap-5">
						{thumbNail?.uploaded
							?
							<>
								<div key={thumbNail.uid}>
									<LazyLoadImage
										alt={`file-${thumbNail.uid}`}
										src={thumbNail?.filePath ?? ''}
										style={{maxHeight: 400, maxWidth: 400}}
									/>
								</div>
								<Button icon={<DeleteOutlined/>} onClick={() => handleDeleteThumbNail()}/>
							</>
							: <Upload listType="picture" {...thumbNailProps}>
								<Button icon={<UploadOutlined/>} type="primary">Upload Thumbnail</Button>
							</Upload>
						}
					</div>
					
					<Typography.Text>Main Image</Typography.Text>
					
					<div className="flex items-center gap-5">
						{mainImage?.uploaded
							?
							<>
								<div key={mainImage.uid}>
									<LazyLoadImage
										alt={`file-${mainImage.uid}`}
										src={mainImage?.filePath ?? ''}
										style={{maxHeight: 400, maxWidth: 400}}
									/>
								</div>
								<Button icon={<DeleteOutlined/>} onClick={() => handleDeleteMainImage()}/>
							</>
							: <Upload listType="picture" {...uploadMainImageProps}>
								<Button icon={<UploadOutlined/>} type="primary">Upload Main Image</Button>
							</Upload>
						}
					</div>
					
					<br/><br/>
					<Typography.Text>Type</Typography.Text>
					<Select
						placeholder="Select the type of this post"
						style={{width: '100%'}}
						mode="multiple"
						onChange={(value) => handleFieldChanges('postType', value)}
						value={fields.postType}
						options={[
							{value: 'all', label: 'All'},
							{value: 'branding', label: 'Branding'},
							{value: 'rebranding', label: 'Rebranding'},
							{value: 'packaging_design', label: 'Packaging Design'},
							{value: 'projects_on_sale', label: 'Projects on Sale'},
						]}
					/>
					<br/><br/>
					
					<br/>
					<Typography.Text>Client</Typography.Text>
					<Input
						name="client"
						placeholder="Client"
						value={fields.client}
						onChange={(e) => handleFieldChanges(e.target.name, e.target.value)}
					/>
					<br/><br/>
					<Typography.Text>Category</Typography.Text>
					<Input
						name="category"
						placeholder="Category"
						value={fields.category}
						onChange={(e) => handleFieldChanges(e.target.name, e.target.value)}
					/>
					
					<br/><br/>
					<Typography.Text>Country</Typography.Text>
					<CountriesSelectInput value={fields.country} onChange={handleFieldChanges}/>
					<br/><br/>
					
					<Typography.Text>Service</Typography.Text>
					<TextArea
						rows={4}
						placeholder="Service"
						name="service"
						value={fields.service}
						onChange={(e) => handleFieldChanges(e.target.name, e.target.value)}
					/>
					<br/><br/>
					<Typography.Text>Description</Typography.Text>
					<TextArea
						rows={4}
						placeholder="Description"
						name="description"
						value={fields.description}
						onChange={(e) => handleFieldChanges(e.target.name, e.target.value)}
					/>
					<br/><br/>
					<div>
						{inputsForMedia.map((input, index) => (
							<div key={input.id} className="pt-5">
								{input.value === '' ? (
									input.type === 'file' ? (
										<Upload listType="picture" onChange={(info) => handleMediaUpload(info, index)} {...additionalMediaProps}>
											{!input.uploaded &&
												<Button icon={<UploadOutlined/>} type="primary">Upload image</Button>}
										</Upload>
									) : (
										<div className="flex gap-5">
											<Input
												value={input.value}
												status={!input.isValid ? "error" : ""}
												placeholder="Add YouTube/Vimeo Link"
												onChange={(info) => handleIframeAttachment(info, index)}
											/>
											<Button icon={<DeleteOutlined/>} onClick={() => handleDeleteInput(index)}/>
										</div>
									)
								) : (
									input.type === 'file' ? (
										<div className="flex items-center gap-5">
											<div key={input.uid}>
												<LazyLoadImage
													alt={`file-${index}`}
													src={input?.value ?? ''}
													style={{maxHeight: 400, maxWidth: 400}}
												/>
											</div>
											<Button icon={<DeleteOutlined/>} onClick={() => handleDeleteInput(index)}/>
										</div>
									) : (
										<div className="flex gap-5">
											<Input
												value={input.value}
												status={!validateUrl(input.value) ? "error" : ""}
												placeholder="Add YouTube/Vimeo Link"
												onChange={(e) => {
													const newFields = [...inputsForMedia];
													newFields[index].value = e.target.value;
													newFields[index].isValid = validateUrl(e.target.value);
													setMediaInputs(newFields);
												}}
											/>
											<Button icon={<DeleteOutlined/>} onClick={() => handleDeleteInput(index)}/>
										</div>
									)
								)}
							</div>
						))}
					</div>
					
					<div className="mt-5">
						<Button type="primary" onClick={addAssetInput}>Add asset +</Button>
						<Button className="ml-5" type="primary" onClick={addLinkInput}>Attach iframe/Video URL
							+</Button>
					</div>
					
					<hr className="mt-5"/>
					<div className="mt-5 flex justify-end">
						<Button type="primary" onClick={handleSubmit}>Submit</Button>
					</div>
				</div>
			</div>
		</Dashboard>
	);
};

export default EditPost;
