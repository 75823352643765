import Dashboard from "../components/Dashboard";
import {Button, message, Upload, Image} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import axios from "axios";

const About = () => {
	const [aboutImage, setAboutImage] = useState({uploaded: false, imagePath: ''});
	const [aboutImagePreview, setAboutImagePreview] = useState({ imagePath: ''});
	
	const beforeUpload = (file) => {
		const acceptedMimeTypes = ["image/png", "image/jpeg", "image/gif", "image/jpg", "image/webp"];
		
		if (!acceptedMimeTypes.includes(file.type)) {
			message.error('You can only upload JPG/PNG file!');
			return Upload.LIST_IGNORE;
		}
	};
	
	const updateDocument = async (path) => {
		try {
			 await axios.post(
				`${process.env.REACT_APP_NODE_APP_API_URL}/content-management/about`,
				JSON.stringify({imagePath: path}),
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					withCredentials: true
				}
			);
			
			
		} catch (ex) {
			console.log(ex)
		}
	};
	
	const aboutImageProps = {
		name: 'file',
		action: `${process.env.REACT_APP_NODE_APP_API_URL}/posts/assets/upload`,
		withCredentials: true,
		beforeUpload,
		data: {uniqueId: 'about'},
		async onChange(info)  {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				setAboutImage({
					uploaded: true,
					imagePath: info.file.response.filePath
				})
				setAboutImagePreview({
					imagePath: info.file.response.filePath
				})
				await updateDocument(info.file.response.filePath);
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onRemove(file) {
			setAboutImage({
				uploaded: false,
				fileimagePath: '',
			})
			message.success(`${file.name} file removed successfully`);
		},
		maxCount: 1,
	}
	
	const setDocumentImage = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_NODE_APP_API_URL}/content-management/about`,
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					withCredentials: true,
				}
			);
			
			if (response.status === 200) {
				setAboutImagePreview({
					imagePath: response.data.imagePath
				})
				setAboutImage({
					imagePath: response.data.imagePath
				})
			}
			
		} catch (ex) {
			console.log(ex)
		}
	};
	
	useEffect(() => {
		setDocumentImage()
	}, []);
	
	return (
		<>
			<Dashboard>
				<div>
					<h1 className="font-poppins" style={{fontSize: 28, fontWeight: 500}}>About Us</h1>
				</div>
				<div className="mb-10">
					<Image
						width={500}
						src={aboutImagePreview.imagePath}
					/>
				</div>
				
				<hr/>
				
				<div className="mt-10">
					<Upload listType="picture" {...aboutImageProps}>
						{!aboutImage.uploaded &&
							<Button icon={<UploadOutlined/>} type="primary">Upload Main Image</Button>}
					</Upload>
				</div>
			</Dashboard>
		</>
	);
};

export default About;
