import React from "react";
import Dashboard from "../components/Dashboard";
import CountriesSelectInput from "../components/CountriesSelectInput";
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import {Input, Select, Button, Upload, message, Typography} from "antd";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import {useNavigate} from "react-router-dom";
const { TextArea } = Input;

const CreatePost = () => {
	const navigate = useNavigate();
	const [uniqueId, setUniqueId] = React.useState(uuidv4());
	const [uniqueIdTh, setUniqueIdTh] = React.useState(uuidv4());
	const [fields, setFields] = React.useState({
		postType: ['all'],
		title: '',
		client: '',
		category: '',
		country: '',
		service: '',
		description: '',
	});
	
	const handleFieldChanges = (name, value) => {
		setFields((prevFields) => ({
			...prevFields,
			[name]: value,
		}));
	}
	
	const [mainImage, setMainImage] = React.useState({uploaded: false, filePath: ''});
	const [thumbNail, setThumbnail] = React.useState({uploaded: false, filePath: ''});
	const [inputsForMedia, setMediaInputs] = React.useState([]);
	
	const addAssetInput = () => {
		if (inputsForMedia.some(input => input.type === 'file' && input.value === '')) {
			message.error('Attach images for all uploaders before adding a new one');
			return;
		}
		
		setMediaInputs([...inputsForMedia, {id: inputsForMedia.length + 1, value: '', type: 'file'}])
	};
	
	const addLinkInput = () => {
		setMediaInputs([...inputsForMedia, {id: inputsForMedia.length + 1, value: '', type: 'link', isValid: false}])
	};
	
	const beforeUpload = (file) => {
		const acceptedMimeTypes = ["image/png", "image/jpeg", "image/gif", "image/jpg", "image/webp"];
		
		if (!acceptedMimeTypes.includes(file.type)) {
			message.error('You can only upload JPG/PNG file!');
			return Upload.LIST_IGNORE;
		}
	};
	
	
	const sharedImageProps = {
		name: 'file',
		action: `${process.env.REACT_APP_NODE_APP_API_URL}/posts/assets/upload`,
		withCredentials: true,
		beforeUpload,
		maxCount: 1,
	}
	
	const mainImageProps = {
		...sharedImageProps,
		data: {uniqueId: uniqueId},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				setMainImage({
					uploaded: true,
					filePath: info.file.response.filePath
				})
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onRemove(file) {
			setMainImage({
				uploaded: false,
				filePath: '',
			})
			message.success(`${file.name} file removed successfully`);
		},
	}
	
	const thumbNailProps = {
		...sharedImageProps,
		data: {uniqueId: uniqueIdTh},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				setThumbnail({
					uploaded: true,
					filePath: info.file.response.filePath
				})
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onRemove(file) {
			setThumbnail({
				uploaded: false,
				filePath: '',
			})
			message.success(`${file.name} file removed successfully`);
		},
	}
	
	
	const props = {
		name: 'file',
		action: `${process.env.REACT_APP_NODE_APP_API_URL}/posts/assets/upload`,
		withCredentials: true,
		data: {uniqueId: uniqueId},
		maxCount: 1,
		beforeUpload
	};
	
	const handleMediaUpload = (info, index) => {
		const newFields = [...inputsForMedia];
		if (info.file.status !== 'uploading') {
			console.log(info.file, info.fileList);
		}
		if (info.file.status === 'done') {
			newFields[index].uploaded = true;
			newFields[index].value = info.file.response.filePath;
			newFields[index].filePath = info.file.response.filePath;
			
			message.success(`${info.file.name} file uploaded successfully`);
		} else if (info.file.status === 'error') {
			inputsForMedia[index].value = null;
			inputsForMedia[index].uploaded = false;
			newFields[index].filePath = '';
			message.error(`${info.file.name} file upload failed.`);
		}
		setMediaInputs(newFields)
		console.log(inputsForMedia)
	}
	
	const validateUrl = (url)  => {
		const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
		const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/;
		return youtubeRegex.test(url) || vimeoRegex.test(url);
	};
	
	
	const handleIframeAttachment = (info, index) => {
		const newFields = [...inputsForMedia];
		inputsForMedia[index].value = info.target.value;
		inputsForMedia[index].isValid = validateUrl(info.target.value);
		setMediaInputs(newFields);
	}
	
	const handleDeleteInput = (index) => {
		const newFields = inputsForMedia.filter((_, i) => i !== index);
		setMediaInputs(newFields);
	};
	
	const handleSubmit = async () => {
		if (!fields.title) {
			message.error('Please enter a title!');
			return;
		} else if (!mainImage.uploaded) {
			message.error('Please add a main image!');
			return;
		} else if (!thumbNail.uploaded) {
			message.error('Please add a thumbnail!');
			return;
		}
		
		if (inputsForMedia.some(input => input.type === 'link' && !input.isValid)) {
			message.error('Please provide valid YouTube/Vimeo links for link inputs.');
			return;
		}
		
		const formData = {
			...fields,
			mainImage,
			thumbNail,
			inputsForMedia,
			assetsDir: uniqueId,
		}
		
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_NODE_APP_API_URL}/posts/create`,
				JSON.stringify(formData),
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					withCredentials: true
				}
			);
			
			if (response.status === 201) {
				message.success('created')
				setTimeout(function () {
					navigate('/dashboard/posts');
				}, 1500)
				
			}
		} catch (error) {
			message.error(error.messages)
		}

		
		
	};
	

	
	return (
		<>
			<Dashboard>
				<div>
					<div>
						<h1 className="font-poppins" style={{fontSize: 28, fontWeight: 500}}>Post Creation</h1>
					</div>
					<div>
						<Input name="title" placeholder="Title" value={fields.title}
							   onChange={(e) => handleFieldChanges(e.target.name, e.target.value)}/>
						<br/><br/>
						<Select
							placeholder="Select the type of this post"
							style={{width: '100%'}}
							onChange={(value) => handleFieldChanges('postType', value)}
							mode="multiple"
							value={fields.postType}
							options={[
								{
									value: 'all',
									label: 'All',
								},
								{
									value: 'branding',
									label: 'Branding',
								},
								{
									value: 'rebranding',
									label: 'Rebranding',
								},
								{
									value: 'packaging_design',
									label: 'Packaging Design',
								},
								{
									value: 'projects_on_sale',
									label: 'Projects on Sale',
								},
							]}
						/>
						
						<div style={{paddingTop: 10}}></div>
						<Typography.Text>Thumbnail</Typography.Text>
						<div >
							<Upload listType="picture" {...thumbNailProps}>
								{!thumbNail.uploaded &&
									<Button icon={<UploadOutlined/>} type="primary">Upload ThumbNail</Button>}
							</Upload>
						</div>
						<br/>
						<Typography.Text>Main Image</Typography.Text>
						<div>
							<Upload listType="picture" {...mainImageProps}>
								{!mainImage.uploaded &&
									<Button icon={<UploadOutlined/>} type="primary">Upload Main Image</Button>}
							</Upload>
						</div>
						<br/>
						<Input name="client" placeholder="Client" value={fields.client}
							   onChange={(e) => handleFieldChanges(e.target.name, e.target.value)}/>
						<br/><br/>
						<Input name="category" placeholder="Category" value={fields.category}
							   onChange={(e) => handleFieldChanges(e.target.name, e.target.value)}/>
						<br/><br/>
						<CountriesSelectInput value={fields.country} onChange={handleFieldChanges}/>
						<br/><br/>
						<TextArea rows={4} placeholder="Service" name="service" value={fields.service}
								  onChange={(e) => handleFieldChanges(e.target.name, e.target.value)}/>
						<br/><br/>
						<TextArea rows={4} placeholder="Description" name="description" value={fields.description}
								  onChange={(e) => handleFieldChanges(e.target.name, e.target.value)}/>
						<br/><br/>
						
						<div>
							{inputsForMedia.map((input, index) => (
								<div key={input.id} className="pt-5">
									{input.type === 'file'
										?
										<Upload listType="picture"
												onChange={(info) => handleMediaUpload(info, index)} {...props} >
											{!input.uploaded &&
												<Button icon={<UploadOutlined/>} type="primary">Upload image</Button>}
										</Upload>
										
										: <div className="flex gap-5">
											<Input
												value={input.value}
												status={!input.isValid ? "error" : ""}
												placeholder="Add Youtoube/Vimeo Link"
												onChange={(info) => handleIframeAttachment(info, index)}
											/>
											<Button icon={<DeleteOutlined/>} onClick={() => handleDeleteInput(index)}/>
										</div>
										
									}
								</div>
							))
							}
						</div>
						<div className="mt-5">
							<Button type="primary" onClick={addAssetInput}>Add asset +</Button>
							<Button className="ml-5" type="primary" onClick={addLinkInput}>Attach iframe/Video URL
								+</Button>
						</div>
						
						<hr className="mt-5" />
						
						<div className="mt-5 flex justify-end">
							<Button type="primary" onClick={handleSubmit}>Submit</Button>
						</div>
					</div>
				
				</div>
			</Dashboard>
		</>
	);
};

export default CreatePost;
